import commonConfigs from "../../config";
import axios from "axios";
import timeout from "../../common/component/InactiveTimeout";
export const isLogin = async () => {
  var status = false;
  // if(timeout){
  //     alert("You have been logged out due to inactivity. Please login again");
  //     // window.location = '/portal/login';
  //     // localStorage.clear();
  //     // return false;
  // }
  if (localStorage.getItem("accessToken")) {
    await axios
      .get(commonConfigs.apiUrls.tokenExpiry(), {
        headers: commonConfigs.apiHeader,
      })
      .then((res) => {
        status = true;
        return status;
      })
      .catch((err) => {
        status = false;
        if (err.response.status == 401) {
          alert(
            "Your session has expired or you have login on another browser/mobile device. Please Login again."
          );
          window.location = "/portal/login";
        }else{
            status = true;
        }
        return status;
      });
    //console.log(status);
    return status;
  } else if (!localStorage.getItem("accessToken")) {
    status = false;
    alert("You are not authenticated. Please Login.");
    window.location = "/portal/login";
    return status;
  } else {
    status = false;
    alert("You are not authenticated. Please Login.");
    window.location = "/portal/login";
    return status;
  }
};
