import React, { Component } from "react";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import "./common/assets/scss/style.scss";
import PrivateRoute from "./auth_module/routing_module/PrivateRoute";
import PublicRoute from "./auth_module/routing_module/PublicRoute";
import * as Sentry from "@sentry/react";

// import moment from 'moment-timezone';
// moment.tz.setDefault("Asia/Singapore");
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./common/containers/TheLayout"));

// Pages
const LandingPage = React.lazy(() => import("./landing_module/landingPage"));
const CheckStatus = React.lazy(() => import("./landing_module/appStatus"));
const Login = React.lazy(() => import("./auth_module/login/LoginPage"));

//Application Pages
const Application = React.lazy(() =>
  import("./landing_module/application_module/application")
);

// const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Otp = React.lazy(() => import("./auth_module/otp/FirebaseAuth"));
function FallbackComponent() {
  console.log("An error has occured.");
  
}
const myFallback = <Redirect from="/" to="/portal/500" />;

class App extends Component {
  render() {
    document.title = "FFFA IMS";
    //localStorage.clear();
    return (
      <Sentry.ErrorBoundary fallback={myFallback}>
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/"
                name="Landing Page"
                component={(props) => <LandingPage {...props} />}
              />
              <Route
                exact
                path="/application/status"
                name="Application Status"
                component={(props) => <CheckStatus {...props} />}
              />
              <PublicRoute
                exact
                path="/application"
                name="Application Form"
                component={(props) => <Application {...props} />}
              />
              <Route
                exact
                path="/portal/login"
                name="Login Page"
                component={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/portal/otp"
                name="Otp Page"
                component={(props) => <Otp {...props} />}
              />
              <Route
                exact
                path="/portal/404"
                name="Page 404"
                component={(props) => <Page404 {...props} />}
              />
              <Route
                restricted={false}
                exact
                path="/portal/500"
                name="Page 500"
                component={(props) => <Page500 {...props} />}
              />
              <PrivateRoute
                path="/portal"
                name="Home"
                component={(props) => <TheLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </Sentry.ErrorBoundary>
    );
  }
}

export default App;
