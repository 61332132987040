var baseUrl = "https://api.uat.fffa-ims.org/api";
if (process.env.REACT_APP_ENV == "prod") {
  baseUrl = "https://api.fffa-ims.org/api";
}
// console.log(baseUrl);
//  let baseUrl = "http://localhost:8080/api/portal";

const commonConfigs = {
  apiHeader: {
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
  apiUrls: {
    checkApplnStatus: () => `${baseUrl}/external/checkApplnStatus`,
    checkApplnExist: () => `${baseUrl}/external/checkApplnExist`,
    aidAppln: () => `${baseUrl}/external/aidAppln`,
    //-------------------------------------------------------------- INBOUND API START ------------------------------------------------------------------------------------------
    //====== LANDING API ======
    getLanding: () => `${baseUrl}/inbound/dashboard/getLanding`,
    createOpeningInventory: () =>
      `${baseUrl}/inbound/dashboard/createOpeningInventory`,
    getMonthlyLanding: (id) =>
      `${baseUrl}/inbound/dashboard/getMonthlyLanding/${id}`,
    updateInbound: () => `${baseUrl}/inbound/dashboard/updateInbound`,
    deleteInbound: (id) => `${baseUrl}/inbound/dashboard/deleteInbound/${id}`,

    //====== PROJECTION API =======
    generateProjection: () =>
      `${baseUrl}/inbound/purchased/projection/generateProjection`,
    updateProjection: () =>
      `${baseUrl}/inbound/purchased/projection/updateProjection`,
    getProjection: (id) =>
      `${baseUrl}/inbound/purchased/projection/getProjection/${id}`,
    //====== PO API =======
    generatePurchaseOrder: () =>
      `${baseUrl}/inbound/purchased/po/generatePurchaseOrder`,
    getPurchasedOrder: (id, type) =>
      `${baseUrl}/inbound/purchased/po/getPurchasedOrder/${id}/${type}`,
    updatePurchaseOrderDetails: () =>
      `${baseUrl}/inbound/purchased/po/updatePurchaseOrderDetails`,
    updatePurchaseOrder: () =>
      `${baseUrl}/inbound/purchased/po/updatePurchaseOrder`,
    approvePurchaseOrder: (id) =>
      `${baseUrl}/inbound/purchased/po/approvePurchaseOrder/${id}`,
    rejectPurchaseOrder: () =>
      `${baseUrl}/inbound/purchased/po/rejectPurchaseOrder`,
    downloadPurchaseOrder: (id) =>
      `${baseUrl}/export/inbound/downloadPurchaseOrder/${id}`,

    //====== OPENING API =======
    confirmTo3PL: () => `${baseUrl}/inbound/purchased/opening/confirmTo3PL`,
    getOpeningInventory: (id, type) =>
      `${baseUrl}/inbound/purchased/opening/getOpeningInventory/${id}/${type}`,
    updateOpening: () => `${baseUrl}/inbound/purchased/opening/updateOpening`,
    downloadPurchaseOpening: (id) =>
      `${baseUrl}/export/inbound/downloadPurchaseOpening/${id}`,
    downloadAdminPurchaseOpening: (id) =>
      `${baseUrl}/export/inbound/downloadAdminPurchaseOpening/${id}`,

    createExpiry: () => `${baseUrl}/inbound/purchased/opening/createExpiry`,
    updateLocation: () => `${baseUrl}/inbound/purchased/opening/updateLocation`,

    getOpeningDetails: (id) =>
      `${baseUrl}/inbound/purchased/opening/getOpeningDetails/${id}`,

    //====== STOCK IN API =======
    updateStockItem: () => `${baseUrl}/inbound/purchased/stock/updateStockItem`,
    downloadStockIn: () => `${baseUrl}/export/inbound/downloadStockIn`,

    getStockIn: (type) =>
      `${baseUrl}/inbound/purchased/stock/getStockIn/${type}`,
    //====== STOCK OUT API =======
    getStockOut: (id, type, mode) =>
      `${baseUrl}/inbound/purchased/stock/getStockOut/${id}/${type}/${mode}`,
    updateStockOut: () => `${baseUrl}/inbound/purchased/stock/updateStockOut`,
    //====== UNPACK API =======
    downloadUnpack: () => `${baseUrl}/export/inbound/downloadUnpack`,

    getUnpackList: (type) =>
      `${baseUrl}/inbound/purchased/unpack/getUnpackList/${type}`,
    assignUnpackList: () =>
      `${baseUrl}/inbound/purchased/unpack/assignUnpackList`,
    //====== CLOSING API =======
    downloadClosing: (inboundid) =>
      `${baseUrl}/export/inbound/downloadClosing/${inboundid}`,

    getClosing: (type, mode) =>
      `${baseUrl}/inbound/purchased/closing/getClosing/${type}/${mode}`,
    updateClosing: () => `${baseUrl}/inbound/purchased/closing/updateClosing`,
    generateClosing: (id) =>
      `${baseUrl}/inbound/purchased/closing/generateClosing/${id}`,

    //====== DONATION API =======
    getDonation: (type, mode) =>
      `${baseUrl}/inbound/donation/getDonation/${type}/${mode}`,
    createDonation: () => `${baseUrl}/inbound/donation/createDonation`,
    updateDonation: () => `${baseUrl}/inbound/donation/updateDonation`,
    deleteDonation: (id) => `${baseUrl}/inbound/donation/deleteDonation/${id}`,

    //====== DONATION OPENING API =======
    getDonatedOpening: (type, mode) =>
      `${baseUrl}/inbound/donation/getDonatedOpening/${type}/${mode}`,
    confirmDonatonTo3PL: () =>
      `${baseUrl}/inbound/donation/confirmDonatonTo3PL`,

    //====== DONATION STOCK IN API =======
    getDonationStockIn: (mode) =>
      `${baseUrl}/inbound/donation/getDonationStockIn/${mode}`,
    updateDonationStockItem: () =>
      `${baseUrl}/inbound/donation/updateDonationStockItem`,

    //====== DONATION ALLOCATE API =======
    getAllocation: (type, mode) =>
      `${baseUrl}/inbound/donation/getAllocation/${type}/${mode}`,
    allocateDonation: () => `${baseUrl}/inbound/donation/allocateDonation`,
    updateAllocation: () => `${baseUrl}/inbound/donation/updateAllocation`,
    rejectAllocation: () => `${baseUrl}/inbound/donation/rejectAllocation`,
    confirmAllocation: (id, user) =>
      `${baseUrl}/inbound/donation/confirmAllocation/${id}/${user}`,
    deleteAllocation: (id) =>
      `${baseUrl}/inbound/donation/deleteAllocation/${id}`,

    //====== DONATION BONUS PACK API =======
    getBonusPack: (id) => `${baseUrl}/inbound/donation/getBonusPack/${id}`,
    confirmPack: (id) => `${baseUrl}/inbound/donation/confirmPack/${id}`,
    deletePack: (id) => `${baseUrl}/inbound/donation/deletePack/${id}`,
    assignPurchasedProgram: () =>
      `${baseUrl}/inbound/donation/assignPurchasedProgram`,
    rejectPack: () => `${baseUrl}/inbound/donation/rejectPack`,
    //====== DONATION STOCK OUT API =======
    getDonationStockOut: (id, type) =>
      `${baseUrl}/inbound/donation/getDonationStockOut/${id}/${type}`,

    //-------------------------------------------------------------- INBOUND API END ------------------------------------------------------------------------------------------

    //-------------------------------------------------------------- OUTBOUND API START ------------------------------------------------------------------------------------------
    // ================================== LANDING API =================================================
    getOutboundLanding: () => `${baseUrl}/outbound/getOutboundLanding`,
    generatePurchasedDelivery: () =>
      `${baseUrl}/outbound/generatePurchasedDelivery`,
    downloadDelivery: (type, vendor, id) =>
      `${baseUrl}/export/outbound/downloadDelivery/${type}/${vendor}/${id}`,
    updateDownloadStatus: (type, vendor, id) =>
      `${baseUrl}/export/outbound/updateDownloadStatus/${type}/${vendor}/${id}`,
    // ================================== PURCHASED API =================================================
    getPurchasedDeliveryLanding: (type, status) =>
      `${baseUrl}/outbound/getPurchasedDeliveryLanding/${type}/${status}`,
    getClientOutboundOrder: (type, status) =>
      `${baseUrl}/outbound/getClientOutboundOrder/${type}/${status}`,
    singleSubmitUnpackList: (id, progid) =>
      `${baseUrl}/outbound/singleSubmitUnpackList/${id}/${progid}`,
    submitAllUnpackList: () => `${baseUrl}/outbound/submitAllUnpackList`,
    getPurchasedAllocation: (type) =>
      `${baseUrl}/outbound/getPurchasedAllocation/${type}`,
    assignBonusPack: () => `${baseUrl}/outbound/assignBonusPack`,
    updatePurchasedDelivery: () =>
      `${baseUrl}/outbound/updatePurchasedDelivery`,

    // ================================== DONATED API =================================================
    getDonatedDelivery: (type) =>
      `${baseUrl}/outbound/getDonatedDelivery/${type}`,
    ackDelivery: () => `${baseUrl}/outbound/ackDelivery`,
    updateDonatedDelivery: () => `${baseUrl}/outbound/updateDonatedDelivery`,

    // ================================== UPLOAD API =================================================
    uploadPurchasedOutbound: () =>
      `${baseUrl}/outbound/uploadPurchasedOutbound`,
    uploadDonatedOutbound: () => `${baseUrl}/outbound/uploadDonatedOutbound`,
    //-------------------------------------------------------------- OUTBOUND API END ------------------------------------------------------------------------------------------

    //=========================== AUTHENTICATION API ===========================
    login: () => `${baseUrl}/auth/login`,
    tokenExpiry: () => `${baseUrl}/auth/expiryCheck`,
    // =========================== FORM DATA API ===========================
    getRoleDropdown: () => `${baseUrl}/common/getRoleDropdown`,
    getAccessDropdown: () => `${baseUrl}/common/getAccessDropdown`,
    getSupplierDropdown: () => `${baseUrl}/common/getSupplierDropdown`,
    getItemDropdown: () => `${baseUrl}/common/getItemDropdown`,
    getPackDropdown: () => `${baseUrl}/common/getPackDropdown`,
    getProgramDropdown: (type) =>
      `${baseUrl}/common/getProgramDropdown/${type}`,
    getProgramMultiDropdown: (type) =>
      `${baseUrl}/common/getProgramMultiDropdown/${type}`,
    getAdHocPackDropdown: () =>
      `${baseUrl}/common/getAdHocPack`,
    getUserDropdown: () => `${baseUrl}/common/getUserDropdown`,
    getZoneDropdown: () => `${baseUrl}/common/getZoneDropdown`,
    getZoneByProgramme: (progid, type) =>
      `${baseUrl}/common/getZoneByProgramme/${progid}/${type}`,
    getDonatedItems: (id) => `${baseUrl}/common/getDonatedItems/${id}`,
    getClientByZoneProgramme: (zoneid, progid, inboundid) =>
      `${baseUrl}/common/getClientByZoneProgramme/${zoneid}/${progid}/${inboundid}`,
    getDonatedItemsMulti: (id) =>
      `${baseUrl}/common/getDonatedItemsMulti/${id}`,
    getCategoryDropdown: () => `${baseUrl}/common/getCategoryDropdown`,
    getAccessMultiDropdown: () => `${baseUrl}/common/getAccessMultiDropdown`,
    getAvailableMonthDropdown: () =>
      `${baseUrl}/common/getAvailableMonthDropdown`,
    getItemSkuDropdown: () => `${baseUrl}/common/getItemSkuDropdown`,

    updateWastage: () => `${baseUrl}/common/updateWastage`,

    //=========================== USER MANAGEMENT API ===========================
    // ==== ROLE & ACCESS RIGHTS ====
    getRole: () => `${baseUrl}/user/getRole`,
    createRole: () => `${baseUrl}/user/createRole`,
    updateRole: () => `${baseUrl}/user/updateRole`,
    deleteRole: (id) => `${baseUrl}/user/deleteRole/${id}`,
    // ==== USER ====

    getUser: () => `${baseUrl}/user/getUser`,
    createUser: () => `${baseUrl}/user/createUser`,
    updateUser: () => `${baseUrl}/user/updateUser`,
    deleteUser: (id) => `${baseUrl}/user/deleteUser/${id}`,
    // ==== SUPPLIER ====
    getSupplier: () => `${baseUrl}/user/getSupplier`,
    createSupplier: () => `${baseUrl}/user/createSupplier`,
    updateSupplier: () => `${baseUrl}/user/updateSupplier`,
    deleteSupplier: (id) => `${baseUrl}/user/deleteSupplier/${id}`,

    //=========================== PROGRAMME MANAGEMENT API ===========================
    getProgramme: () => `${baseUrl}/programme/getProgramme`,
    createProgramme: () => `${baseUrl}/programme/createProgramme`,
    updateProgramme: () => `${baseUrl}/programme/updateProgramme`,
    deleteProgramme: (id) => `${baseUrl}/programme/deleteProgramme/${id}`,

    //=========================== ZONE MANAGEMENT API ===========================
    // ==== ZONE ===
    getZone: () => `${baseUrl}/zone/getZone`,
    createZone: () => `${baseUrl}/zone/createZone`,
    updateZone: () => `${baseUrl}/zone/updateZone`,
    deleteZone: (id) => `${baseUrl}/zone/deleteZone/${id}`,

    // ==== ZONE IC ====
    getZoneIc: (id) => `${baseUrl}/zone/getZoneIc/${id}`,
    createZoneIc: () => `${baseUrl}/zone/createZoneIc`,
    updateZoneIc: () => `${baseUrl}/zone/updateZoneIc`,
    deleteZoneIc: (id) => `${baseUrl}/zone/deleteZoneIc/${id}`,
    uploadZoneIc: () => `${baseUrl}/zone/uploadZoneIc`,
    additonalZoneIc: () => `${baseUrl}/zone/additonalZoneIc`,

    //=========================== INVENTORY MANAGEMENT API ===========================

    // ==== ITEM ===
    getItem: () => `${baseUrl}/inventory/getItem`,
    createItem: () => `${baseUrl}/inventory/createItem`,
    updateItem: () => `${baseUrl}/inventory/updateItem`,
    deleteItem: (id) => `${baseUrl}/inventory/deleteItem/${id}`,

    // ==== PACKING LIST===
    getItemPack: () => `${baseUrl}/inventory/getItemPackage`,
    createItemPack: () => `${baseUrl}/inventory/createItemPackage`,
    updateItemPack: () => `${baseUrl}/inventory/updateItemPackage`,
    deleteItemPack: (id) => `${baseUrl}/inventory/deleteItemPackage/${id}`,

    // ==== ITEM IN PACK===
    getItemInPack: (id) => `${baseUrl}/inventory/getItemInPack/${id}`,
    createItemInPack: () => `${baseUrl}/inventory/createItemInPack`,
    updateItemInPack: () => `${baseUrl}/inventory/updateItemInPack`,
    deleteItemInPack: (id) => `${baseUrl}/inventory/deleteItemInPack/${id}`,
    downloadPackItem: (id) => `${baseUrl}/export/misc/downloadPackItem/${id}`,

    //=========================== CLIENT MANAGEMENT API ===========================
    getClient: () => `${baseUrl}/client/getClient`,
    uploadClient: () => `${baseUrl}/client/uploadClient`,
    updateDetails: () => `${baseUrl}/client/updateDetails`,
    reassignClient: () => `${baseUrl}/client/reassignClient`,
    deleteClient: (id) => `${baseUrl}/client/deleteClient/${id}`,
    getCreditDetails: (id) => `${baseUrl}/client/getCreditDetails/${id}`,
    exportClient: (status) => `${baseUrl}/export/misc/exportClient/${status}`,

    // ================ DEPENDANT API ============
    getDependant: (id) => `${baseUrl}/client/getDependant/${id}`,
    updateDependant: () => `${baseUrl}/client/updateDependant`,
    createDependant: () => `${baseUrl}/client/createDependant`,
    deleteDependant: (id, clientid) =>
      `${baseUrl}/client/deleteDependant/${id}/${clientid}`,

    // ==== FOOD AID APPLICATION API ===
    getFoodAidApplication: (status, clientstatus) =>
      `${baseUrl}/client/getFoodAidApplication/${status}/${clientstatus}`,
    getApplnDetails: (id) => `${baseUrl}/client/getApplnDetails/${id}`,
    approveAidAppln: () => `${baseUrl}/client/approveAidAppln`,
    rejectAidAppln: () => `${baseUrl}/client/rejectAidAppln`,
    updateApplicantStatus: (id, userid) =>
      `${baseUrl}/client/updateApplicantStatus/${id}/${userid}`,
    getOutboundTransaction: (id) =>
      `${baseUrl}/client/getOutboundTransaction/${id}`,
    getClientOrder: (id) => `${baseUrl}/client/getClientOrder/${id}`,
  },
};
export default commonConfigs;
